import { useAppContext } from "Context/AppProvider";
import QueueMenu from "./QueueMenu";
import OpenRequestsList from "./OpenRequestsList";
import InProgressList from "./InProgressList";
import PaddedContent from "Layouts/PaddedContent";
import IdleMentorModal from "Components/IdleMentorModal";
import ForcedOffDutyModal from "Components/ForcedOffDutyModal";
import SettingsConditional from "Components/SettingsConditional";
import OpenSecondaryTasksList from "./OpenSecondaryTasksList";

const Queue = () => {
  const {
    currentUser,
    tasksHandler: {
      error,
      inProgress,
      inProgressAIOnly,
      pendingEvaluations,
      allOpenSecondaryTasksForUser,
      pendingAssistanceRequests,
    },
    userActivityHandler: {
      getShowIdleAlertValue,
      hideIdleAlert,
      getShowForcedOffDutyAlertValue,
      hideForcedOffDutyAlert,
    },
    studentHandler: { students },
  } = useAppContext();

  const inProgressRequests = [...inProgress(), ...inProgressAIOnly()];

  return (
    <PaddedContent>
      {getShowIdleAlertValue() && (
        <IdleMentorModal hide={hideIdleAlert} forcingOffDutyAt={getShowIdleAlertValue()} />
      )}
      {getShowForcedOffDutyAlertValue() && <ForcedOffDutyModal hide={hideForcedOffDutyAlert} />}
      {error && (
        <div className="alert alert-danger">
          <strong>{error}</strong>
        </div>
      )}
      <div className="queue-by-location queue-show">
        <SettingsConditional flag={SettingsConditional.MENTOR_QUEUE_NOTE} />
        <QueueMenu currentUser={currentUser} />
        <>
          <div className="queue-column queue-top mt-3 ">
            <OpenRequestsList
              assistanceRequests={pendingAssistanceRequests()}
              currentUser={currentUser}
            />
          </div>
          <div className="queue-column queue-mid mt-3 ">
            <SettingsConditional flag={SettingsConditional.SECONDARY_TASK_QUEUE}>
              <OpenSecondaryTasksList
                tasks={allOpenSecondaryTasksForUser()}
                currentUser={currentUser}
              />
            </SettingsConditional>
          </div>
          <div className="queue-row queue-bottom ">
            <div className="queue-column left">
              <InProgressList tasks={inProgressRequests} />
              <SettingsConditional
                flag={SettingsConditional.OPEN_REQUESTS}
                tasks={pendingEvaluations()}
              />
            </div>
            <div className="queue-column right">
              <SettingsConditional flag={SettingsConditional.STUDENTS_LIST} students={students} />
            </div>
          </div>
        </>
      </div>
    </PaddedContent>
  );
};

export default Queue;
